// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-ls-tsx": () => import("./../../../src/pages/ls.tsx" /* webpackChunkName: "component---src-pages-ls-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-tech-tsx": () => import("./../../../src/pages/tech.tsx" /* webpackChunkName: "component---src-pages-tech-tsx" */)
}

